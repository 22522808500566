/* eslint-disable react-compiler/react-compiler */
/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, type ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventNames } from 'constants/event-bus-events';
import { EventPlace } from 'helpers/analytics';
import { mapSources } from 'helpers/copilot/copilot';
import { uniqueId } from 'helpers/string';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useParamSelector } from 'hooks/use-param-selector';
import { streamCopilotClient } from 'services/connectivity/ml-gateway-api/copilot-stream/client';
import { type CopilotStreamEvent } from 'services/connectivity/ml-gateway-api/copilot-stream/types';
import { EventBus } from 'services/event-bus';
import { trackEvent } from 'services/event-tracking';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { AIAgent } from 'store/entities/ai-agents/constants';
import { getAIAgentId } from 'store/entities/ai-agents/selectors';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';
import { type Source } from 'store/entities/copilot/interfaces';
import { CopilotViewActions } from 'store/views/copilot/actions';

import { useCopilotContext } from './hooks/use-copilot-context';

type CopilotMessageContext = {
  message: string;
};

type CopilotMessageContextValue = {
  handleSendMessage: (message: string) => void;
  abortStream: () => void;
} & CopilotMessageContext;

const CopilotMessageContext = createContext<CopilotMessageContextValue | undefined>(undefined);

type CopilotMessage = {
  traceId: string;
  message: string;
  sources: Source[];
  sessionId: string;
};

const EMPTY_ARRAY: Source[] = [];

const INITIAL_COPILOT_MESSAGE: CopilotMessage = {
  traceId: '',
  message: '',
  sources: EMPTY_ARRAY,
  sessionId: '',
};

export function CopilotMessageContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const [message, setMessage] = useState('');
  const { appContext } = useCopilotContext();

  const abortController = useRef<AbortController>(new AbortController());
  const copilotMessageRef = useRef<CopilotMessage>(INITIAL_COPILOT_MESSAGE);

  const updateCopilotMessage = useCallback((value: Partial<CopilotMessage>) => {
    copilotMessageRef.current = {
      ...copilotMessageRef.current,
      ...value,
    };
  }, []);

  const copilotAiAgentId = useParamSelector(getAIAgentId, AIAgent.One);
  const currentAgentLogin = useSelector(getLoggedInAgentLogin);
  const dispatch = useDispatch();

  const clearCopilotMessageRef = useCallback(() => {
    copilotMessageRef.current = INITIAL_COPILOT_MESSAGE;
  }, []);

  const abortStream = useCallback(() => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    setMessage('');

    let message = '_response canceled_';
    if (copilotMessageRef.current.message) {
      message = `${copilotMessageRef.current.message} _(response cancelled)_`;
    }

    const eventId = uniqueId();
    dispatch(
      CopilotEntitiesActions.addCopilotMessage({
        authorId: 'live-assistant',
        authorType: 'live-assistant',
        eventId,
        traceId: copilotMessageRef.current.traceId,
        sessionId: uniqueId(),
        text: message,
        timestampInMs: Date.now(),
        type: 'text-message',
        properties: {
          sources: copilotMessageRef.current?.sources || EMPTY_ARRAY,
          withoutActions: !copilotMessageRef.current.message,
          hasCancelledRequest: true,
        },
      }),
    );

    dispatch(CopilotViewActions.setCopilotIsLoading(false));
  }, [dispatch]);

  const parseMessage = useCallback(
    (message: CopilotStreamEvent) => {
      switch (message.type) {
        case 'message': {
          setMessage(message.response);

          updateCopilotMessage({ message: message.response });
          break;
        }
        case 'reasoning-step-started': {
          break;
        }
        case 'tool-used': {
          updateCopilotMessage({ message: message.response });
          setMessage(message.response);
          break;
        }
        case 'conversation-metadata': {
          const sources = message?.sources ? mapSources(message.sources) : [];
          const traceId = message.trace_id;
          updateCopilotMessage({ sources, traceId });
          trackEvent(CopilotTrackEvent.MessageReceived, EventPlace.One, { result: 'success' });
          break;
        }
        default: {
          break;
        }
      }
    },
    [updateCopilotMessage],
  );

  const sendAgentMessage = useCallback(
    (message: string) => {
      const agentEventId = uniqueId();

      dispatch(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: currentAgentLogin,
          authorType: 'agent',
          eventId: agentEventId,
          text: message,
          timestampInMs: Date.now(),
          type: 'text-message',
        }),
      );
    },
    [dispatch, currentAgentLogin],
  );

  const handleError = useCallback(
    (message: string) => {
      return () => {
        setMessage('');
        dispatch(CopilotEntitiesActions.addCopilotError(true));
        dispatch(CopilotEntitiesActions.setFailedCopilotMessage(message));
        dispatch(CopilotViewActions.setCopilotIsLoading(false));
        trackEvent(CopilotTrackEvent.MessageReceived, EventPlace.One, { result: 'error' });
      };
    },
    [dispatch],
  );

  const handleClose = useCallback(() => {
    setMessage('');
    if (copilotMessageRef.current.message) {
      dispatch(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          traceId: copilotMessageRef.current.traceId,
          text: copilotMessageRef.current.message,
          timestampInMs: Date.now(),
          type: 'text-message',
          sessionId: copilotMessageRef.current.sessionId,
          properties: {
            sources: copilotMessageRef.current.sources || EMPTY_ARRAY,
          },
        }),
      );
    }
    clearCopilotMessageRef();
    dispatch(CopilotViewActions.setCopilotIsLoading(false));
  }, [dispatch, clearCopilotMessageRef]);

  const handleSendMessage = useCallback(
    async (message: string) => {
      if (!copilotAiAgentId) {
        return;
      }

      setMessage('');
      clearCopilotMessageRef();
      dispatch(CopilotViewActions.setCopilotIsLoading(true));
      dispatch(CopilotEntitiesActions.clearCopilotError());

      sendAgentMessage(message);

      updateCopilotMessage({ sessionId: uniqueId() });

      try {
        await streamCopilotClient.sendMessage({
          payload: {
            messages: [{ value: message }],
            executor: 'conversation',
            agent_id: copilotAiAgentId,
            user_context: appContext,
          },
          signal: abortController.current.signal,
          handlers: {
            onMessage: parseMessage,
            onError: handleError(message),
            onClose: handleClose,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    [
      appContext,
      copilotAiAgentId,
      dispatch,
      handleClose,
      handleError,
      parseMessage,
      sendAgentMessage,
      updateCopilotMessage,
      clearCopilotMessageRef,
    ],
  );

  const value: CopilotMessageContextValue = useMemo(
    () => ({
      message,
      handleSendMessage,
      abortStream,
    }),
    [message, handleSendMessage, abortStream],
  );

  useEffectOnce(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    EventBus.on(EventNames.SendCopilotMessageFromSpotlight, handleSendMessage);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      EventBus.off(EventNames.SendCopilotMessageFromSpotlight, handleSendMessage);
    };
  });

  return <CopilotMessageContext.Provider value={value}>{children}</CopilotMessageContext.Provider>;
}

export function useCopilotMessageContext(): CopilotMessageContextValue {
  const context = useContext(CopilotMessageContext);
  if (context === undefined) {
    throw new Error('useCopilotMessageContext must be used within a CopilotMessageContextProvider');
  }

  return context;
}
