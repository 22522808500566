import { type SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import agentCustomPropertiesSagas from './agent-custom-properties/sagas';
import { applicationsSagas } from './applications/sagas';
import attachmentsFeatureSagas from './attachments/sagas';
import browserNotificationsSagas from './browser-notifications/sagas';
import { chatButtonsSaga } from './chat-buttons/sagas';
import { codeInstallationSaga } from './code-installation/sagas';
import { contextEventModalSagas } from './context-event/sagas';
import { customSegmentsSagas } from './custom-segments/sagas';
import { externalAppsSagas } from './external-apps/sagas';
import googleTagManagerSagas from './google-tag-manager/sagas';
import { inviteLinkSagas } from './invitation-link/sagas';
import notificationsSaga from './notifications/sagas';
import { notificationsBarSaga } from './notifications-bar/sagas';
import { routingSaga } from './routing/sagas';
import sessionSagas from './session/sagas';
import { setupRiskDetectionSagas } from './signup-risk-detection/sagas';
import { smartClientSaga } from './smart-client/sagas';
import soundNotificationsSagas from './sound-notifications/sagas';
import { statusReminderSagas } from './status-reminder/sagas';
import { tabNotificationsSaga } from './tab-notifications/sagas/tab-notifications';
import toastSagas from './toasts/sagas';
import { tooltipsSagas } from './tooltips/sagas';
import transcriptFeatureSagas from './transcript/sagas';
import { trialExpiringModalSagas } from './trial-expiration/sagas';
import urlQueryParamsFeatureSaga from './url-query-params/sagas';

export function* featuresSagas(): SagaIterator {
  yield all([
    fork(agentCustomPropertiesSagas),
    fork(applicationsSagas),
    fork(attachmentsFeatureSagas),
    fork(browserNotificationsSagas),
    fork(chatButtonsSaga),
    fork(codeInstallationSaga),
    fork(customSegmentsSagas),
    fork(googleTagManagerSagas),
    fork(notificationsBarSaga),
    fork(notificationsSaga),
    fork(routingSaga),
    fork(smartClientSaga),
    fork(soundNotificationsSagas),
    fork(sessionSagas),
    fork(setupRiskDetectionSagas),
    fork(tabNotificationsSaga),
    fork(toastSagas),
    fork(transcriptFeatureSagas),
    fork(trialExpiringModalSagas),
    fork(urlQueryParamsFeatureSaga),
    fork(tooltipsSagas),
    fork(inviteLinkSagas),
    fork(statusReminderSagas),
    fork(externalAppsSagas),
    fork(contextEventModalSagas),
  ]);
}
