import { type SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';

import { GlobalModalActions } from '../global-modals/actions';

import { getShouldShowContextModal } from './selectors';

function* contextEventSaga(): SagaIterator {
  const shouldShowModal = yield select(getShouldShowContextModal);
  if (!shouldShowModal) {
    return;
  }

  if (shouldShowModal) {
    yield put(GlobalModalActions.showModal(GlobalModal.ContextModal));
  }
}

export function* contextEventModalSagas(): SagaIterator {
  yield takeEvery('APP_READY', contextEventSaga);
}
