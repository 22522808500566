import { differenceInDays } from 'date-fns';

import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

import { EXPIRED_COPY, ONE_DAY_COPY, FOUR_DAYS_COPY, SEVEN_DAYS_COPY, type ExpirationCopy } from './constants';

const MIGRATION_DATE = new Date('2024-12-03');

export const getDaysUntilMigration = (): number => {
  const today = new Date();

  return differenceInDays(MIGRATION_DATE, today);
};

export const getCopy = (daysUntilMigration: number): ExpirationCopy | null => {
  if (daysUntilMigration < 0) {
    return EXPIRED_COPY;
  }

  switch (daysUntilMigration) {
    case 1:
      return ONE_DAY_COPY;
    case 4:
      return FOUR_DAYS_COPY;
    case 7:
      return SEVEN_DAYS_COPY;
    default:
      return null;
  }
};

export const mapDaysUntilMigrationToAgentCustomPropertyName = (
  daysUntilMigration: number,
): AgentCustomPropertyName | null => {
  if (daysUntilMigration === 1) return AgentCustomPropertyName.ContextModalSeen1DayBeforeMigration;
  if (daysUntilMigration === 4) return AgentCustomPropertyName.ContextModalSeen4DaysBeforeMigration;
  if (daysUntilMigration === 7) return AgentCustomPropertyName.ContextModalSeen7DaysBeforeMigration;
  if (daysUntilMigration < 0) return AgentCustomPropertyName.ContextModalSeenAfterMigration;

  return null;
};
