import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';

import { AbsoluteAutomateRoutes } from 'constants/automate/constants';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { KnowledgeHubEvent } from 'constants/knowledge-hub';
import { QueryKey } from 'constants/query-key';
import { EventPlace } from 'helpers/analytics';
import { navigate } from 'helpers/routing';
import { uniqueId } from 'helpers/string';
import { type KnowledgeSourceItem } from 'interfaces/knowledge-sources';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { getLoggedInAgentName } from 'store/entities/agents/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { getAgentCustomProperty } from 'store/features/agent-custom-properties/selectors';
import { type IActionWithPayload } from 'store/helper';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCopilotIsLoading } from 'store/views/copilot/selectors';

import { CopilotEntitiesActions } from '../actions';
import { KNOWLEDGE_HUB_EXPLORE_KNOWLEDGE_HUB_MESSAGE, KNOWLEDGE_HUB_TELL_ME_MORE_MESSAGE } from '../constants';
import { setUnreadMessagesCount } from '../helper-sagas';
import { type CopilotOnboardingActionPayload } from '../interfaces';

import { COPILOT_ONBOARDING_PRIORITY } from './constants';
import { type OnboardingConfig } from './copilot-onboarding-config';

export function* getOnboardingToStart(): SagaIterator {
  const isCopilotTyping = yield select(getCopilotIsLoading);

  if (isCopilotTyping) {
    return null;
  }

  const hasSeenCopilotOnboarding: boolean = yield select(
    getAgentCustomProperty,
    AgentCustomPropertyName.OnePopoverOnboardingSeen,
  );

  return COPILOT_ONBOARDING_PRIORITY.find((onboardingName) => {
    switch (onboardingName) {
      case 'copilot-onboarding':
        return !hasSeenCopilotOnboarding;
      default:
        return null;
    }
  });
}

export function* handleCopilotOnboardingAction(
  action: IActionWithPayload<string, CopilotOnboardingActionPayload>,
): SagaIterator {
  const { content, props, eventId } = action.payload;

  yield put(
    CopilotEntitiesActions.updateCopilotEvent({
      eventId,
      type: 'text-message',
      properties: {
        withoutActions: true,
      },
    }),
  );

  yield put(
    CopilotEntitiesActions.addCopilotMessage({
      authorId: 'agent',
      authorType: 'agent',
      eventId: uniqueId(),
      text: content,
      timestampInMs: Date.now(),
      type: 'text-message',
    }),
  );

  if (props.navigateUrl) {
    navigate(props.navigateUrl);
    trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Adding source clicked' });
  }

  if (props.delay) {
    yield put(CopilotViewActions.setCopilotIsLoading(true));
    yield delay(props.delay);
    yield put(CopilotViewActions.setCopilotIsLoading(false));
  }

  switch (props.responseType) {
    case 'final-message':
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: `I’m here if you need any help! 👍`,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Explore' });
      break;
    case 'knowledge-hub-info':
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: KNOWLEDGE_HUB_TELL_ME_MORE_MESSAGE,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Tell me more' });
      break;
    case 'explore-knowledge-hub':
      navigate(AbsoluteAutomateRoutes.KnowledgeHubAllSources);
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: KNOWLEDGE_HUB_EXPLORE_KNOWLEDGE_HUB_MESSAGE,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      yield put(
        CopilotEntitiesActions.addCopilotMessage({
          authorId: 'live-assistant',
          authorType: 'live-assistant',
          eventId: uniqueId(),
          text: `I’m here if you need any help! 👍`,
          timestampInMs: Date.now(),
          type: 'text-message',
          properties: {
            withoutActions: true,
          },
        }),
      );
      trackEvent(KnowledgeHubEvent.OnboardingSuccesfull, EventPlace.One, { button: 'Explore knowledge hub' });
      break;
  }

  yield call(setUnreadMessagesCount);
}

export function* startCopilotOnboarding(config: OnboardingConfig): SagaIterator {
  trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding started' });

  const loggedAgentName: string = yield select(getLoggedInAgentName);

  const queryClient = getQueryClient();
  const knowledgeSources = queryClient.getQueryData<KnowledgeSourceItem[]>([QueryKey.KnowledgeSourcesList]);
  const hasAnyKnowledgeSources = !!knowledgeSources?.length;

  for (const getMessage of config) {
    const message = getMessage({ name: loggedAgentName, hasAnyKnowledgeSources });

    if (!message) {
      continue;
    }

    yield put(
      CopilotEntitiesActions.addCopilotMessage({
        authorId: 'live-assistant',
        authorType: 'live-assistant',
        eventId: uniqueId(),
        text: message.text,
        timestampInMs: Date.now(),
        type: 'text-message',
        properties: {
          actionButtons: message.actionButtons ?? undefined,
          withoutActions: true,
        },
      }),
    );

    if (message.delay) {
      yield put(CopilotViewActions.setCopilotIsLoading(true));
      yield delay(message.delay);
      yield put(CopilotViewActions.setCopilotIsLoading(false));
    }

    yield call(setUnreadMessagesCount);
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.OnePopoverOnboardingSeen]: true,
    }),
  );
  trackEvent(CopilotTrackEvent.Onboarding, EventPlace.One, { step: 'Onboarding finished' });
}
