import { ChatType } from 'constants/chat-type';
import type { Permission } from 'constants/permission';
import type { SectionName } from 'constants/section-name';
import type { EarlyAccessConfig } from 'helpers/early-access/interfaces';
import type { KeyMap } from 'helpers/interface';
import type { CustomSegment } from 'interfaces/custom-segment';
import type { ISerializedReportView } from 'interfaces/reports';
import type { ISerializedInsightsHintsStatus, ISerializedInsightsMetrics } from 'store/entities/insights/interfaces';
import type { RequestFailure } from 'store/entities/interfaces';
import type { ILegacySegment } from 'store/views/traffic/interfaces';

export const enum AgentCustomPropertyName {
  AddPrivateNoteTooltipSeen = 'add_private_note_tooltip_seen',
  ChatbotInLivechatNavItemBadgeClicked = 'chatbot_in_livechat_nav_item_badge_clicked',
  ArchiveDetailsOrder = 'archive_custom_sections_order',
  ArchiveDetailsSelected = 'archive_custom_sections_selected',
  ArchiveListMode = 'archive_list_mode',
  ArchivesTagPickerTooltipSeen = 'archives_tag_picker_tooltip_seen',
  AskForEmailBannerSeen = 'ask_for_email_banner_seen',
  AutomateVisited = 'automate_visited',
  CannedResponseHeaderVisible = 'canned_response_header_visible',
  ChatCannedResponsesTooltipSeen = 'chat_canned_responses_tooltip_seen',
  ChatListMode = 'chat_list_mode',
  ChatsRoutingSettingsTooltipSeen = 'chats_routing_settings_tooltip_seen',
  ChatTakeoverTooltipSeen = 'chats_takeover_tooltip_seen',
  ChatWindowGreetingsSkipped = 'chat_window_greetings_skipped',
  CountryIsoFilterSeen = 'country_iso_filter_seen',
  CustomerCustomColumns = 'customer_custom_columns',
  CustomerCustomColumnsOrder = 'customer_custom_columns_order',
  CustomerHiddenColumns = 'customer_hidden_columns',
  CustomerDetailsSectionSelected = 'customer_details_sections_selected',
  CustomerDetailsSectionsOrder = 'customer_details_sections_order',
  CustomerListTourSeen = 'customer_list_tour_seen',
  CustomersSegmentsDefaultApplied = 'customers_segments_default_applied',
  CustomersSegmentsPrefix = 'customers_segment_',
  CustomersSegmentsTourDone = 'customers_segments_tour_done',
  CustomerUpdateTooltipSeen = 'customer_update_tooltip_seen',
  CustomSegmentOrder = 'order',
  CustomSegmentPrefix = 'custom_segment_',
  CustomSoundsTooltipSeen = 'custom_sounds_tooltip_seen',
  DesktopAppInstalled = 'desktop_app_installed',
  DetailsPanelOnboardingFinishedHD = 'details_panel_onboarding_finished_hd',
  DetailsPanelOnboardingFinishedKB = 'details_panel_onboarding_finished_kb',
  ExportCSVInappSeen = 'lc3.reports.export_csv',
  FiltersOperatorsTooltipSeen = 'filters_operators_tooltip_seen',
  GreetingsTourSeen = 'greetings_tour_seen',
  HelpDeskBoosterTooltipSeen = 'helpdesk_booster_tooltip_seen',
  HelpDeskNavPromoSeen = 'helpdesk_nav_promo_seen',
  HomeScreenChecklistClosed = 'home_screen_checklist_closed',
  Last7DaysBannerSeen = 'last_7_days_banner_seen',
  LeaveAFeedbackBannerSeen = 'leave_a_feedback_banner_seen',
  LogoRebrandingTooltipSeen = 'logo_rebranding_tooltip_seen',
  MessagingInQueuePromoBannerSeen = 'messaging_in_queue_promo_banner_seen',
  MessagingModeTicketFormPromoBannerSeen = 'messaging_mode_ticket_form_promo_banner_seen',
  MissedChatsBannerSeen = 'missed_chats_banner_seen',
  NewAvailabilityPromoBannerSeen = 'new_availability_promo_banner_seen',
  NewVisitorTooltipViewed = 'new_visitor_tooltip_viewed',
  OpenChatTooltipSeen = 'open_chat_tooltip_seen',
  PasteAttachmentTooltipSeen = 'paste_attachment_tooltip_seen',
  ReportsMissedChatsInfoBannerSeen = 'reports_missed_chats_info_banner_seen',
  ReportsTotalChatsInfoBannerSeen = 'reports_total_chats_info_banner_seen',
  ReportsTourSeen = 'reports_tour_seen',
  ReportViewPrefix = 'report_view_',
  ReportViewsIntroTooltipSeen = 'report_views_intro_tooltip_seen',
  ReportViewsTourDone = 'report_views_tour_done',
  RichGreetingEditTooltipSeen = 'rich_greeting_edit_tooltip_seen',
  RichGreetingGuidedTourDone = 'rich_greeting_guided_tour_done',
  SectionDetailsOpen = 'section_details_open',
  ShopifyCustomerInsightsTooltipSeen = 'shopify_customer_insights_tooltip_seen',
  ChatFeedHelpDeskInfoboxSeen = 'chat_feed_helpdesk_infobox_seen',
  StatusReminderSkips = 'status_reminder_skips',
  SuggestedResponseVisible = 'suggested_response_visible',
  TargetedMessagesTooltipSeen = 'targeted_messages_tooltip_seen',
  TeamApproveAllSeen = 'team-approve-all-seen',
  TicketPrivacyTooltipSeen = 'ticket_privacy_tooltip_seen',
  TicketStatusTooltipSeen = 'ticket_status_tooltip_seen',
  TimeFormat = 'time_format',
  MyChatSortType = 'my_chat_sort_type',
  UnassignedChatSortType = 'unassigned_chat_sort_type',
  QueuedSortType = 'queued_sort_type',
  UtmSource = 'utm_source',
  SuggestedResponseTooltipSeen = 'suggested_response_tooltip_seen',
  AreCannedResponsesSuggestionsVisible = 'are_canned_responses_suggestions_visible',
  PinnedTags = 'pinned-tags',
  ClearSearchWithEachSelection = 'clear-search-with-each-selection',
  RolesInOrganization = 'roles_in_organization',
  TextEnhancementOnboardingTooltipSeen = 'text_enhancement_onboarding_tooltip_seen',
  CurrentTheme = 'current_theme',
  InsightsMetrics = 'insights_metrics',
  InsightsHints = 'insights_hints',
  InsightsIntroTooltipSeen = 'insights_intro_tooltip_seen',
  OnboardingWelcomeMessageAIEnhancementNumber = 'onboarding_welcome_message_ai_enhancement_number',
  OnboardingGreetingAIEnhancementNumber = 'onboarding_greeting_ai_enhancement_number',
  MainFocus = 'main_focus',
  ChatSummaryTooltipSeen = 'chat_summary_tooltip_seen',
  ChatVisibilityHelpDeskInfoboxSeen = 'chat_visibility_helpdesk_infobox_seen',
  CannedResponseAutotagsPromoSeen = 'canned_response_autotags_promo_seen',
  FirstAutomationBotMessageSeen = 'first_automation_bot_message_seen',
  ChatsListSentiment = 'chats_list_sentiment',
  KnowledgeSourcesPromoSeen = 'knowledge_sources_promo_seen',
  SavedInvitations = 'saved_invitations',
  OnboardingSuggestedListOpen = 'onboarding_suggested_list_open',
  OnboardingCompletedChecklistSession = 'onboarding_completed_checklist_session',
  TeamSettingsButtonSeen = 'team_settings_button_seen',
  TransferPromoTooltipSeen = 'transfer_promo_tooltip_seen',
  ReportVisibleColumnsPrefix = 'report_visible_columns_',
  ReportColumnsOrderPrefix = 'report_columns_order_',
  ReplySuggestionsPromo = 'reply_suggestions_promo',
  ReplySuggestionsPromoModalSeen = 'reply_suggestions_promo_modal_seen',
  ReplySuggestionsButtonVisible = 'reply_suggestions_button_visible',
  EnableChatSummaryOnTransfer = 'enable_chat_summary_on_transfer',
  OneChatOnboardingSeen = 'live_assistant_chat_onboarding_seen',
  OnePopoverOnboardingSeen = 'one_popover_onboarding_seen',
  InviteModeChangeBannerSeen = 'invite_mode_change_banner_seen',
  EarlyAccessConfig = 'early_access_config',
  ReplySuggestionsBannerClosed = 'reply_suggestions_banner_closed',
  ReplySuggestionsLimitsAlertClosed = 'reply_suggestions_limits_alert_closed',
  ReplySuggestionsLimitsAlertSeen = 'reply_suggestions_limits_alert_seen',
  ExceededSessionsCount = 'exceeded_sessions_count',
  OnboardingIntent = 'onboarding_intent',
  PaymentErrorOnAccessBlocked = 'payment_error_on_access_blocked',
  IntegrationsOrder = 'integrations_order',
  IntentCampaignTooltipSeen = 'intent_campaign_tooltip_seen',
  CampaignPromoBannerHidden = 'campaign_promo_banner_hidden',
  ExitIntentPromoBannerHidden = 'exit_intent_promo_banner_hidden',
  ReplySuggestionsOnboardingSeen = 'reply_suggestions_onboarding_seen',
  DictionaryAutocomplete = 'dictionary_autocomplete',
  AICannedAutocomplete = 'ai_canned_autocomplete',
  SpotlightActionsOnboardingSeen = 'spotlight_actions_onboarding_seen',
  AICannedAutocompleteTooltip = 'ai_canned_autocomplete_tooltip',
  KnowledgeHubAllSourcesBannerClosed = 'knowledge-hub-all-sources-banner-closed',
  KnowledgeHubWebsitesBannerClosed = 'knowledge_hub_websites_banner_closed',
  KnowledgeHubPdfBannerClosed = 'knowledge_hub_pdfs_banner_closed',
  AddWebsiteAsSourceBannerClosed = 'add_website_as_source_banner_closed',
  PreviewSourceTooltipClosed = 'preview_source_tooltip_closed',
  DownloadDesktopAppBannerNextVisibilityDate = 'download_desktop_app_banner_next_visibility_date',
  AgentDetailsSectionsOrder = 'agent_details_sections_order',
  GroupDetailsSectionsOrder = 'group_details_sections_order',
  NativeTicketsSubsetBannerNextVisibilityDate = 'native_tickets_sunset_banner_next_visibility_date',
  ContextModalSeenAfterMigration = 'context_modal_seen_after_migration',
  ContextModalSeen7DaysBeforeMigration = 'context_modal_seen_7_days_before_migration',
  ContextModalSeen4DaysBeforeMigration = 'context_modal_seen_4_days_before_migration',
  ContextModalSeen1DayBeforeMigration = 'context_modal_seen_1_day_before_migration',
  PriorityChatTooltip = 'priority_chat_tooltip',
  CustomerCustomSelectedApplications = 'customer_custom_selected_applications',
  CustomerCustomUnselectedApplications = 'customer_custom_unselected_applications',
  CustomerCustomApplicationsOrder = 'customer_custom_applications_order',
}

export interface IAgentCustomPropertiesState extends KeyMap<AgentCustomPropertyDataType> {}

export type AgentCustomPropertyDataType =
  | string
  | string[]
  | number
  | boolean
  | ICustomersSegmentAgentCustomProperty
  | ICustomSegmentsAgentCustomProperty
  | IReportViewsAgentCustomProperty
  | IInsightsMetricsAgentCustomProperty
  | IInsightsHintsStatusAgentCustomProperty
  | ICannedResponseAutotagsPromoCustomProperty
  | SavedInvitationsProperty
  | IKnowledgeSourcesPromoCustomProperty
  | IReplySuggestionsPromoCustomProperty
  | IReportColumnData
  | Partial<EarlyAccessConfig>;

export const PersistedChatsSortType = {
  [ChatType.Unassigned]: AgentCustomPropertyName.UnassignedChatSortType,
  [ChatType.My]: AgentCustomPropertyName.MyChatSortType,
  [ChatType.Queued]: AgentCustomPropertyName.QueuedSortType,
};

export enum ArchiveListMode {
  Detailed = 'detailed',
  Default = 'default',
  Compact = 'compact',
}

export enum ChatListMode {
  Detailed = 'detailed',
  Default = 'default',
}

export interface IDeleteAgentCustomPropertyPayload {
  id: string;
}

export interface IDeleteAgentCustomPropertySuccessPayload {
  id: string;
}

export interface IDeleteAgentCustomPropertyFailurePayload extends RequestFailure {}

export interface ISetAgentCustomPropertyPayload {
  [key: string]: AgentCustomPropertyDataType;
}

export interface ISetAgentCustomPropertySuccessPayload {
  values: {
    [key: string]: AgentCustomPropertyDataType;
  };
}

export interface ISetAgentCustomPropertyFailurePayload extends RequestFailure {
  customPropertyIdentifier: AgentCustomPropertyName;
}

export interface IFetchAgentCustomPropertiesSuccessPayload {
  [key: string]: AgentCustomPropertyDataType;
}

export interface IFetchAgentCustomPropertiesFailurePayload extends RequestFailure {}

export interface IUpdateSectionDetailsOpen {
  section: SectionName;
  isOpen: boolean;
}

interface ICustomersSegmentAgentCustomProperty extends ILegacySegment {}

interface ICustomSegmentsAgentCustomProperty extends CustomSegment {}

export interface IReportViewsAgentCustomProperty extends ISerializedReportView {}

export interface IInsightsMetricsAgentCustomProperty extends ISerializedInsightsMetrics {}

export interface IInsightsHintsStatusAgentCustomProperty extends ISerializedInsightsHintsStatus {}

export interface ICannedResponseAutotagsPromoCustomProperty {
  cannedResponseListSeen: boolean;
  cannedResponseListTooltipSeen: boolean;
  cannedResponseChatModalSeen: boolean;
}

export interface IKnowledgeSourcesPromoCustomProperty {
  knowledgeSourcesListSeen: boolean;
  knowledgeSourcesTooltipSeen: boolean;
}

export type LegacySavedInvitationsProperty = {
  emails: string[];
  role: Permission;
  groups: string[];
};
export type SavedInvitationsProperty = ISavedInvitationsItem[] | null;

interface ISavedInvitationsItem {
  email: string;
  role: Permission;
  groups: string[];
}

export interface IReplySuggestionsPromoCustomProperty {
  replySuggestionCount: number;
  dateOfFirstUsage: Date | null;
  displayedSprigFormCount: number;
}

interface IReportColumnData {
  visibleColumns: string[];
  columnsOrder: string[];
}
