export enum ContextModalEvent {
  KeepPlanClicked = 'Keep plan clicked',
  UpgradeClicked = 'Upgrade clicked',
  ModalDisplayed = 'Modal displayed',
}

export interface ExpirationCopy {
  heading: string;
  paragraph: string;
  cta: string;
}

export const EXPIRED_COPY: ExpirationCopy = {
  heading: 'Your Business plan trial has ended',
  paragraph:
    'Your free Business plan access has expired. Upgrade your plan and unlock all the Business plan features again.',
  cta: 'Upgrade',
};

export const ONE_DAY_COPY: ExpirationCopy = {
  heading: 'Your Business plan trial ends tomorrow',
  paragraph: 'Only 1 day left of free Business plan access. Click below to keep enjoying all the features.',
  cta: 'Keep Business plan',
};

export const FOUR_DAYS_COPY: ExpirationCopy = {
  heading: 'Your Business plan trial ends soon',
  paragraph: 'Your free Business plan access ends in 4 days.Click below to keep all the features you love.',
  cta: 'Keep Business plan',
};

export const SEVEN_DAYS_COPY: ExpirationCopy = {
  heading: 'Your Business plan trial ends soon',
  paragraph: 'Only 1 week left to enjoy Business features for free. Click below to keep the benefits forever.',
  cta: 'Keep Business plan',
};
