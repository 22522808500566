import { type PickRequired } from 'helpers/interface';
import { createAction, type ActionsUnion } from 'store/helper';

import {
  type CopilotEvent,
  type CopilotOnboardingActionPayload,
  type CopilotAddConversationPayload,
} from './interfaces';

export enum CopilotEntitiesActionNames {
  ADD_COPILOT_MESSAGE = 'ENTITIES/CHATS/ADD_COPILOT_MESSAGE',
  ADD_COPILOT_ERROR = 'ENTITIES/CHATS/ADD_COPILOT_ERROR',
  CLEAR_COPILOT_ERROR = 'ENTITIES/CHATS/CLEAR_COPILOT_ERROR',
  SET_FAILED_COPILOT_MESSAGE = 'ENTITIES/CHATS/SET_FAILED_COPILOT_MESSAGE',
  UPDATED_COPILOT_ACTION_BUTTONS = 'ENTITIES/CHATS/UPDATED_COPILOT_ACTION_BUTTONS',
  COPILOT_MESSAGE_SPOTLIGHT = 'ENTITIES/CHATS/COPILOT_MESSAGE_SPOTLIGHT',
  COPILOT_ONBOARDING_ACTION = 'ENTITIES/CHATS/COPILOT_ONBOARDING_ACTION',
  COPILOT_ADD_CONVERSATION = 'ENTITIES/CHATS/COPILOT_ADD_CONVERSATION',
}

export const CopilotEntitiesActions = {
  addCopilotMessage: (payload: CopilotEvent) => createAction(CopilotEntitiesActionNames.ADD_COPILOT_MESSAGE, payload),
  addCopilotError: (payload: boolean) => createAction(CopilotEntitiesActionNames.ADD_COPILOT_ERROR, payload),
  clearCopilotError: () => createAction(CopilotEntitiesActionNames.CLEAR_COPILOT_ERROR),
  setFailedCopilotMessage: (payload: string) =>
    createAction(CopilotEntitiesActionNames.SET_FAILED_COPILOT_MESSAGE, payload),
  copilotOnboardingAction: (payload: CopilotOnboardingActionPayload) =>
    createAction(CopilotEntitiesActionNames.COPILOT_ONBOARDING_ACTION, payload),
  updateCopilotEvent: (payload: PickRequired<CopilotEvent, 'eventId'>) =>
    createAction(CopilotEntitiesActionNames.UPDATED_COPILOT_ACTION_BUTTONS, payload),
  setCopilotMessageSpotlight: (payload: boolean) =>
    createAction(CopilotEntitiesActionNames.COPILOT_MESSAGE_SPOTLIGHT, payload),
  addCopilotConversation: (payload: CopilotAddConversationPayload) =>
    createAction(CopilotEntitiesActionNames.COPILOT_ADD_CONVERSATION, payload),
};

export type CopilotEntitiesAction = ActionsUnion<typeof CopilotEntitiesActions>;
