import { useSelector } from 'react-redux';

import { type ISubscription } from 'interfaces/subscription';
import { getIsSubscriptionFetched, getSubscription } from 'store/entities/subscription/selectors';

type UseSubscription = {
  data: ISubscription | null;
  isFetched: boolean;
};

export const useSubscription = (): UseSubscription => {
  const subscription = useSelector(getSubscription);
  const isSubscriptionFetched = useSelector(getIsSubscriptionFetched);

  return {
    data: subscription,
    isFetched: isSubscriptionFetched,
  };
};
