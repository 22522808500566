// @ts-strict-ignore
import createPlatformClient, { type PlatformClient } from '@livechat/platform-client';
import debug from 'debug';

import { type IConfig } from '@config/interfaces';
import { Cookie } from 'constants/cookies';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getCookie } from 'helpers/cookies';
import { type IServer } from 'interfaces/server';

import { type PlatformProtocolParser } from './platform-protocol-parser';

const log = debug(DebugLogsNamespace.AppServerConnection);

interface IPlatformClientOptions {
  transports: ('websocket' | 'xhr-polling')[];
  rescheduleTimeout: number;
  query?: Record<string, string>;
}

export class ServerAccessPlatform implements IServer {
  protocolParser: PlatformProtocolParser;
  platformClient: PlatformClient<unknown, unknown>;
  ghostEnabled = false;
  websocketCompressionEnabled = false;
  listeners = [];
  config: IConfig;
  options: IPlatformClientOptions;

  constructor(protocolParser, config: IConfig) {
    this.protocolParser = protocolParser;

    this.config = config;

    this.options = {
      transports: [
        'websocket',
        // 'xhr-polling'
      ],
      rescheduleTimeout: 15 * 1000, // 15s
    };

    if (getCookie(Cookie.WebsocketsOff)) {
      this.options.transports = ['xhr-polling'];
    }
  }

  setupPlatformClient() {
    const endpoint = `${this.config.api}${this.config.platformSocketOptions.path}`;
    if (this.websocketCompressionEnabled) {
      this.options.query = { compress: this.websocketCompressionEnabled.toString() };
    }
    log('Creating platform client', { endpoint, options: this.options, compression: this.websocketCompressionEnabled });
    this.platformClient = createPlatformClient(endpoint, this.options);
    this.platformClient.on('connect', this.handleConnect);
    this.platformClient.on('disconnect', this.handleDisconnect);
    this.platformClient.on('message', this.handleMessage);
    this.platformClient.on('connection_unstable', this.handleDisconnect);

    this.listeners.forEach(({ event, handler }): void => this.platformClient.on(event, handler));
  }

  dispose() {
    this.listeners.forEach(({ event, handler }): void => this.platformClient.off(event, handler));
  }

  on(event, handler) {
    this.listeners.push({ event, handler });
    this.platformClient?.on(event, handler);
  }

  off(event, handler) {
    this.listeners = this.listeners.filter(({ event: e, handler: h }) => e !== event && h !== handler);
    this.platformClient?.off(event, handler);
  }

  getTransports() {
    return this.options.transports;
  }

  connect(): void {
    this.setupPlatformClient();
    this.platformClient.connect();
  }

  disconnect(): void {
    this.platformClient.disconnect();
  }

  handleConnect = (): void => {
    log('Socket has connected.');
  };

  /**
   * The connection was lost. The reason might be a server restart or network issues.
   */
  handleDisconnect = (): void => {
    log('Socket has disconnected or connection is unstable.');
  };

  handleMessage = (message): void => {
    this.protocolParser.parseWithReconnect(message);
  };

  send(message): void {
    this.platformClient.emit(message);
  }

  /**
   * This method is used to completely disconnect from WebSocket
   * and destroy connection (to prevent automatic reconnects).
   * Also, all listeners are disposed of.
   */
  close(): void {
    if (!this.platformClient) {
      return;
    }

    this.platformClient.disconnect();
    this.platformClient.destroy();
    this.dispose();
  }

  isOpen(): boolean {
    if (!this.platformClient) {
      return false;
    }

    return this.platformClient.getReadyState() === 1;
  }
}
