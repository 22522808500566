import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-restricted-paths
import { getDaysUntilMigration } from 'components/global-modals/context-event/helpers';
import { QueryKey } from 'constants/query-key';
import { PlatformNamespace, BillingProperty } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';

import { getContextModalSeen } from '../agent-custom-properties/selectors';
import { getCanManageSubscription } from '../session/selectors';

export const getShouldShowContextModal = createSelector(
  [getCanManageSubscription, getContextModalSeen],
  (canManageSubscription, contextModalSeen) => {
    const daysUntilMigration = getDaysUntilMigration();

    if (daysUntilMigration === 0) {
      return false;
    }

    const higherPlanTrialAccepted = !!getQueryClient().getQueryData(
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    )?.[BillingProperty.HigherPlanTrialAccepted];

    const higherPlanTrialStarted = !!getQueryClient().getQueryData(
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    )?.[BillingProperty.HigherPlanTrialStarted];

    return higherPlanTrialStarted && !higherPlanTrialAccepted && canManageSubscription && !contextModalSeen;
  },
);
