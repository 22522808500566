import { type StartupData } from 'entries/base/interfaces/startup-data';
import { convertToUnixTimestamp } from 'helpers/date';
import { LicenseType } from 'interfaces/entities/current-license';
import { type Group } from 'interfaces/groups';
import { deserializeAgents } from 'services/api/agent/v3/agent-serializer';
import { deserializeBots } from 'services/api/agent-bot/serializer';
import { deserializeGroups } from 'services/api/group/serializer';
import { type RoutingStatus } from 'services/connectivity/agent-chat-api/status/types';
import { type ListLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';
import { type IAgent } from 'store/entities/agents/interfaces';
import { type IAgentBot } from 'store/entities/bots/interfaces';

interface DeserializedCriticalData {
  licenseId: string;
  organizationId: string;
  createdAt: number;
  expiresAt: number;
  email: string;
  licenseType: LicenseType;
  licenseProperties: ListLicensePropertiesResponse;
  groups: Group[];
  agents: IAgent[];
  bots: IAgentBot[];
}

export function deserializeCriticalData(startupData: StartupData): DeserializedCriticalData {
  const { licenseProperties, groups, agents, bots, routingStatuses, licenseInfo, tokenInfo, account } = startupData;

  const {
    license_id: licenseId,
    created_at: createdAtIsoString,
    expires_at: expiresAtIsoString,
    in_trial: isTrial,
  } = licenseInfo;

  const { organization_id: organizationId } = tokenInfo;

  const { email } = account;

  const createdAt = convertToUnixTimestamp(new Date(createdAtIsoString).getTime());
  const expiresAt = convertToUnixTimestamp(new Date(expiresAtIsoString).getTime());
  const licenseType = isTrial ? LicenseType.Trial : LicenseType.Paid;

  const statuses: Record<string, RoutingStatus> = (routingStatuses || []).reduce(
    (acc, { agent_id: agentId, status }) => ({ ...acc, [agentId]: status }),
    {},
  );

  return {
    licenseId,
    organizationId,
    createdAt,
    expiresAt,
    email: email.toLowerCase(),
    licenseType,
    licenseProperties,
    groups: deserializeGroups(groups || []),
    agents: deserializeAgents(agents, statuses),
    bots: deserializeBots(bots, statuses),
  };
}
